import React from 'react';

const CallBack = () => {
	return (
		<div>
			<form action="https://formspree.io/master@hair.moscow" method="post" className="contact-form-box" encType="multipart/form-data">
				<fieldset>
					<div className="clearfix">
						<div className="col-xs-12 col-md-9">
							<input type="hidden" name="_language" value="ru" />
							<div className="form-group">
								<label htmlFor="name">Ваше имя</label>
								<input type="text" className="form-control" id="name" name="name" />
							</div>
							<div className="form-group">
								<label htmlFor="phone">Ваш телефон или почта для связи</label>
								<input type="text" className="form-control" id="phone" name="phone" />
							</div>
							<div className="form-group">
								<label htmlFor="message">Комментарий</label>
								<input type="text" className="form-control" id="message" name="message" />
							</div>
						</div>
					</div>
					<div className="submit">
						<button type="submit" className="btn btn-default btn-md hm-button">
							<span>
								Отправить запрос<i className="fa fa-chevron-right right"></i>
							</span>
						</button>
					</div>
					<br/>
				</fieldset>
			</form>
		</div>
	);
}

export default CallBack;