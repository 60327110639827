import React, { Component } from 'react';
import CallBack from '../components/call-back';
import { Helmet } from "react-helmet";
import TemplateWrapper from '../components/layout';

class PerezvoniteMne extends Component {
  render() {
    return (
      <TemplateWrapper>
      <div className="article-content">
        <Helmet>
          <title>Задайте нам вопрос и мы Вам перезвоним!</title>
          <meta name="description" content="Форма обратной связи с лучшими мастерами по наращиванию волос в Москве" />
          <meta name="keywords" content="Мастер по наращиванию волос, позвонить, телефон, почта, связаться" />
        </Helmet>
        <div id="columns" className="container">
          <h1 className="hm-title-1">Оставьте свой телефон и вопрос и мы вам перезвоним!</h1>
          <CallBack />
        </div>
      </div>
      </TemplateWrapper>
    );
  }
}

export default PerezvoniteMne;

